import BalanceBox from "../../components/balance-box/balance-box";
import "./home-screen.scss";
import yoyoLogo from "../../assets/yoyo-logo.png";
import PaymentSuccessfulScreen from "../payment-successful-screen/payment-successful-screen";
import PaymentUnsuccessfulScreen from "../payment-unsuccessful-screen/payment-unsuccessful-screen";
import DeleteCardScreen from "../delete-card-screen/delete-card-screen";
import {
  InitiatePayment,
  GetStoredCards,
  DeleteStoredCard,
} from "../../libs/paymentApi";
import { useEffect, useState } from "react";
import { GetCustomerDetails } from "../../libs/customerApi";
import WideButton from "../../components/wide-button/wide-button";
import CardPaymentOption from "../../components/card-payment-option/card-payment-option";
import { useHistory } from "react-router-dom";

const HomeScreen = ({
  showPaymentSuccessfulScreen,
  showPaymentUnsuccessfulScreen,
  paymentResultData,
  paymentReference,
  setCurrentPaymentId,
  currentPaymentId,
}) => {
  const [customerDetails, setCustomerDetails] = useState({});
  const [storedCards, setStoredCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState({});
  const [showDeleteCardScreen, setShowDeleteCardScreen] = useState(false);
  const [deleteCardLoading, setDeleteCardLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setCustomerDetails(GetCustomerDetails);
  }, []);

  useEffect(() => {
    if (currentPaymentId && currentPaymentId.length > 1) {
      history.push("/add-card");
    }
  }, [currentPaymentId]);

  useEffect(() => {
    if (customerDetails.accountNumber) {
      getStoredCards(customerDetails.accountNumber);
    }
  }, [customerDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  const getStoredCards = async () => {
    const result = await GetStoredCards(customerDetails.accountNumber);
    setStoredCards(result);
  };

  const handleAddCardPressed = async () => {
    const paymentDetailsDto = {
      amount: customerDetails.amountDue,
      accountNumber: customerDetails.accountNumber,
    };
    const result = await InitiatePayment(paymentDetailsDto);
    await setCurrentPaymentId(result.id);
  };

  const handleDeleteCardPressed = async (selectedCard) => {
    setShowDeleteCardScreen(true);
    setSelectedCard(selectedCard);
  };

  const handleCancelDeleteCardPressed = async () => {
    setShowDeleteCardScreen(false);
  };

  const handleConfirmDeleteCardPressed = async () => {
    setDeleteCardLoading(true);
    try {
      await DeleteStoredCard(selectedCard.storedCardRef);
      setShowDeleteCardScreen(false);
    } catch {
    } finally {
      setDeleteCardLoading(false);
    }
    await getStoredCards();
  };

  return (
    <div class="content-box">
      <div class="account-selection-box">
        <BalanceBox customerDetails={customerDetails} />
        <div class="account-number-parent-box">
          <div>
            <div class="account-number-text">Account Number</div>
            <div class="account-number-value-text">
              {customerDetails.accountNumber}
            </div>
          </div>
          <div>
            <div class="reference-number-text">Reference Number</div>
            <div class="reference-number-value-text">
              {customerDetails.referenceNumber}
            </div>
          </div>
        </div>
        {storedCards.length > 0 && (
          <div class="heading-text push-down">Payment Method:</div>
        )}
        {storedCards.length > 0 &&
          storedCards.map((storedCard, i) => {
            return (
              <CardPaymentOption
                cardType="mastercard"
                isAlternateRow={i % 2}
                cardDetails={storedCard}
                handleDeleteCardPressed={handleDeleteCardPressed}
              />
            );
          })}
        <div onClick={() => handleAddCardPressed()}>
          <div class="link-button-text">+ ADD CARD</div>
        </div>
        <WideButton buttonText="Pay now" onClickAction={handleAddCardPressed} />
        <div class="bottom-logo-box">
          <img alt="" src={yoyoLogo} class="bottom-logo" />
        </div>
      </div>
      {showPaymentSuccessfulScreen && (
        <PaymentSuccessfulScreen
          paymentResultData={paymentResultData}
          paymentReference={paymentReference}
        />
      )}
      {showPaymentUnsuccessfulScreen && <PaymentUnsuccessfulScreen />}
      {showDeleteCardScreen && (
        <DeleteCardScreen
          handleDeleteCardPressed={handleConfirmDeleteCardPressed}
          cardDetails={selectedCard}
          customerDetails={customerDetails}
          isLoading={deleteCardLoading}
          handleCancelDeleteCardPressed={handleCancelDeleteCardPressed}
        />
      )}
    </div>
  );
};

export default HomeScreen;
