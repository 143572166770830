import "./payment-successful-screen.scss";
import WideButton from "../../components/wide-button/wide-button";
import successIcon from "../../assets/success-icon.png";
import { Link } from "react-router-dom";
import closeIcon from "../../assets/close-icon.png";

const PaymentSuccessfulScreen = ({ paymentResultData, paymentReference }) => {
  return (
    <div class="background-screen-box">
      <div class="payment-successful-screen-modal add-shading">
        <Link to="/" class="close-icon-box">
          <img src={closeIcon} alt="" />
        </Link>
        <div class="bold-centered-heading-text">Payment Succesful</div>
        <div class="result-icon-box">
          <img class="result-icon" src={successIcon} alt="" />
        </div>
        <div class="payment-result-details-box">
          <div class="payment-result-details-row">
            <div class="payment-result-details-lable">Paid</div>
            <div class="payment-result-details-value-text">
              R{paymentResultData.amount}
            </div>
          </div>
          <div class="payment-result-details-row">
            <div class="payment-result-details-lable">Method</div>
            <div class="payment-result-details-value-text">
              CC {"**** **** **** **** " + paymentResultData.card.last4Digits}
            </div>
          </div>
          <div class="payment-result-details-row">
            <div class="payment-result-details-lable">Payment Date</div>
            <div class="payment-result-details-value-text">
              {paymentResultData.timestamp}
            </div>
          </div>
          <div class="payment-result-details-row">
            <div class="payment-result-details-lable">Reference</div>
            <div class="payment-result-details-value-text">
              {paymentReference}
            </div>
          </div>
        </div>
        <Link to="/">
          <WideButton buttonText="Ok" />
        </Link>
        <div class="payment-result-disclaimer">
          Take note It can take up to 48 Hrs for your updated account balance to
          reflect.
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessfulScreen;
