import "./wide-button.scss";

const WideButton = ({
  buttonText,
  alternateStyle,
  staticMargins,
  onClickAction,
  isLoading,
}) => {
  return (
    <div
      onClick={() => (onClickAction ? onClickAction() : () => {})}
      class={`wide-button add-shading ${
        alternateStyle ? "alternate-button-style" : "regular-button-style"
      }
      `}
      style={{
        marginLeft: staticMargins ? "10px" : "20%",
        marginRight: staticMargins ? "10px" : "20%",
      }}
    >
      {!isLoading && buttonText}
      {isLoading && <div class="button-loader"></div>}
    </div>
  );
};

export default WideButton;
