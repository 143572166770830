import "./App.scss";
import HomeScreen from "./screens/home-screen/home-screen";
import Header from "./components/header/header";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ValidatingPaymentScreen from "./screens/validating-payment-screen/validating-payment-screen";
import { useState } from "react";
import AddCardScreen from "./screens/add-card-screen/add-card-screen";

function App() {
  const [paymentResultData, setPaymentResultData] = useState({});
  const [paymentReference, setPaymentReference] = useState("");
  const [currentPaymentId, setCurrentPaymentId] = useState("");

  return (
    <div className="app">
      <Header />
      <Router>
        <Switch>
          <Route path="/payment-complete">
            <ValidatingPaymentScreen
              setPaymentResultData={setPaymentResultData}
              setPaymentReference={setPaymentReference}
            />
          </Route>
          <Route path="/payment-successful">
            <HomeScreen
              showPaymentSuccessfulScreen={true}
              paymentResultData={paymentResultData}
              paymentReference={paymentReference}
            />
          </Route>
          <Route path="/payment-unsuccessful">
            <HomeScreen showPaymentUnsuccessfulScreen={true} />
          </Route>
          <Route path="/delete-card">
            <HomeScreen showDeleteCardScreen={true} />
          </Route>
          <Route path="/add-card">
            <AddCardScreen currentPaymentId={currentPaymentId} />
          </Route>
          <Route path="/">
            <HomeScreen
              showAddCardScreen={false}
              setCurrentPaymentId={setCurrentPaymentId}
              currentPaymentId={currentPaymentId}
            />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
