import { useEffect, useState } from "react";
import BalanceBox from "../../components/balance-box/balance-box";
import PaymentSuccessfulScreen from "../payment-successful-screen/payment-successful-screen";
import "./validating-payment-screen.scss";
import { GetPaymentResult } from "../../libs/paymentApi";
import { GetQueryVariable } from "../../libs/general";
import { useHistory, useLocation } from "react-router-dom";

const ValidatingPaymentScreen = ({
  setPaymentResultData,
  setPaymentReference,
}) => {
  const history = useHistory();

  const [customerDetails, setCustomerDetails] = useState({});
  const [validatingPayment, setValidatingPayment] = useState(true);
  const [paymentResult, setPaymentResult] = useState(""); // eslint-disable-line react-hooks/exhaustive-deps

  const validatePayment = async () => {
    setValidatingPayment(true);
    const paymentReference = await GetQueryVariable("id");
    setPaymentReference(paymentReference);
    let result = null;
    try {
      result = await GetPaymentResult(paymentReference);
      setPaymentResultData(result);
    } catch {
      history.push("payment-unsuccessful");
    }
    if (result) {
      history.push("/payment-successful");
    } else {
      history.push("payment-unsuccessful");
    }
    setValidatingPayment(false);
  };

  useEffect(() => {
    validatePayment();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div class="content-box">
      <div class="account-selection-box">
        <BalanceBox customerDetails={customerDetails} />
      </div>
      {validatingPayment && (
        <div class="validation-box">
          <div class="validating-payment-heading">Validating payment...</div>
          <div class="loader"></div>
        </div>
      )}
      {paymentResult === "success" && <PaymentSuccessfulScreen />}
      {paymentResult === "failed" && (
        <div class="payment-successful">Payment failed</div>
      )}
    </div>
  );
};

export default ValidatingPaymentScreen;
