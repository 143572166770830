export const GetCustomerDetails = () => {
  const customerDetails = {
    accountNumber: "1232132131",
    amountDue: 250,
    referenceNumber: "referen332",
    dueDate: "29 / 11 / 21",
    fullName: "Matthew Thomas",
  };

  return customerDetails;
};
