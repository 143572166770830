import axios from "axios";

const baseUrl = "https://xpayapi.lunchcard.co.za/";

export const InitiatePayment = async (paymentDetails) => {
  const result = await axios.post(
    baseUrl + "payment/initiate-payment",
    paymentDetails
  );
  return result.data;
};

export const GetStoredCards = async (accountNumber) => {
  const result = await axios.get(
    baseUrl + "payment/get-stored-cards?accountNumber=" + accountNumber
  );
  return result.data;
};

export const DeleteStoredCard = async (storedCardRef) => {
  const result = await axios.delete(
    baseUrl + "payment/delete-card?cardRef=" + storedCardRef
  );
  return result.data;
};

export const GetPaymentResult = async (paymentReference) => {
  const result = await axios.get(
    baseUrl + "payment/validate-payment?paymentId=" + paymentReference
  );
  return result.data;
};
