import "./check-box.scss";

const CheckBox = ({ lableText, handleCheckClicked, checkValue }) => {
  return (
    <div class="check-box-parent-box" onClick={handleCheckClicked}>
      <div
        class="check-box"
        style={{ backgroundColor: checkValue ? "#8E8E93" : "unset" }}
      ></div>
      <div class="check-box-lable">{lableText}</div>
    </div>
  );
};

export default CheckBox;
