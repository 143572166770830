import CheckBox from "../../components/check-box/check-box";
import "./add-card-screen.scss";
import WideButton from "../../components/wide-button/wide-button";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import closeIcon from "../../assets/close-icon.png";

const AddCardScreen = ({ currentPaymentId }) => {
  console.log(currentPaymentId);
  const [saveCardChecked, setSaveCardChecked] = useState(false);

  const handleCheckClicked = () => {
    setSaveCardChecked(!saveCardChecked);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://test.oppwa.com/v1/paymentWidgets.js?checkoutId=" +
      currentPaymentId;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div class="background-screen-box">
      <div class="add-card-screen-modal add-shading">
        <Link to="/" class="close-icon-box">
          <img src={closeIcon} alt="" />
        </Link>
        <div class="bold-centered-heading-text">Debit / Credit Card</div>
        <div class="add-payment-form">
          <form
            action="https://x-pay.fluenty.co.za/payment-complete"
            class="paymentWidgets"
            data-brands="VISA MASTER AMEX"
          ></form>
        </div>
        <div class="add-card-screen-checkbox">
          <CheckBox
            lableText="Save card for future payments"
            handleCheckClicked={handleCheckClicked}
            checkValue={saveCardChecked}
          />
        </div>
      </div>
    </div>
  );
};

export default AddCardScreen;
