import "./header.scss";
import headerLogo from "../../assets/rcs-logo.png";

const Header = () => {
  return (
    <div class="header-box">
      <div class="header-logo-box">
        <img alt="" src={headerLogo} class="header-logo" />
      </div>
    </div>
  );
};

export default Header;
