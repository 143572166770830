import "./payment-unsuccessful-screen.scss";
import WideButton from "../../components/wide-button/wide-button";
import failureIcon from "../../assets/failure-icon.png";
import { Link } from "react-router-dom";
import closeIcon from "../../assets/close-icon.png";

const PaymentUnsuccessfulScreen = () => {
  return (
    <div class="background-screen-box">
      <div class="payment-unsuccessful-screen-modal add-shading">
        <Link to="/" class="close-icon-box">
          <img src={closeIcon} alt="" />
        </Link>
        <div class="bold-centered-heading-text">Payment Unsuccessful</div>
        <div class="result-icon-box">
          <img class="result-icon" src={failureIcon} alt="" />
        </div>
        <div class="payment-unsuccessful-text">
          There was a problem with the payment service. Use an alternate card or
          try again later.
        </div>
        <div class="payment-unsuccessful-buttons-box">
          <Link to="/" class="payment-unsuccessful-button">
            <WideButton
              buttonText="Retry"
              alternateStyle={true}
              staticMargins={true}
            />
          </Link>
          <Link to="/" class="payment-unsuccessful-button">
            <WideButton buttonText="Cancel" staticMargins={true} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PaymentUnsuccessfulScreen;
