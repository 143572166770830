import "./card-payment-option.scss";
import mastercardLogo from "../../assets/mastercard-logo.png";
import visaLogo from "../../assets/visa-logo.png";
import deleteIcon from "../../assets/delete-icon.png";

const CardPaymentOption = ({
  cardType,
  cardDetails,
  isAlternateRow,
  handleDeleteCardPressed,
}) => {
  return (
    <div
      class="card-payment-option"
      style={{ backgroundColor: isAlternateRow ? "white" : "#E3E3E3" }}
    >
      <div class="flex-row-box">
        <div class="card-type-logo-box">
          {cardType === "mastercard" && (
            <img alt="" src={mastercardLogo} class="card-type-smaller-logo" />
          )}
          {cardType === "visa" && (
            <img alt="" src={visaLogo} class="card-type-logo" />
          )}
        </div>
        <div class="card-number-text">{cardDetails.cardNumber}</div>
      </div>
      <div class="flex-row-box">
        <div class="cvv-number-box">
          <input type="input" class="cvv-number-field" />
        </div>
        <div
          to="/delete-card"
          class="delete-card-box"
          onClick={() => handleDeleteCardPressed(cardDetails)}
        >
          <img alt="" src={deleteIcon} class="delete-icon" />
        </div>
      </div>
    </div>
  );
};

export default CardPaymentOption;
